@charset "utf-8";

@use './css/primary' as primary;


// Update Bulma Variables Before Importing Bulma
$primary: primary.$primary;
$link: primary.$link;
$info: primary.$info;
$success: primary.$success;
$warning: primary.$warning;
$danger: primary.$danger;


@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/components/card.sass";

figure.image {
    margin: 0;
}

ol {
    list-style-position: inside;
}

ul {
    list-style-position: inside;
    list-style-type: disc;
}

